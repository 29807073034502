import React from "react"
import { Container } from "react-bootstrap"
import styles from "../../scss/components/Labanderaentusmanos/_Main.module.scss"

export default function Main() {
  return (
    <div className={styles.container}>
      <div className={styles.textsContainer}>
        <h1 className={styles.firstText}>¡La bandera firmada por Leo puede ser tuya!</h1>
        <h2 className={styles.secondText}>
        Completá el formulario y participá para ganarte la bandera que firmó Messi antes de ganar la Copa América. 
        </h2>
      </div>
    </div>
  )
}
